import { OktaVerifyTotp } from './OktaVerifyTotp';
import { VerificationCodeAuthenticator } from './VerificationCodeAuthenticator';
import { OktaPassword } from './OktaPassword';
import { SecurityQuestionEnrollment } from './SecurityQuestionEnrollment';
import { SecurityQuestionVerification } from './SecurityQuestionVerification';
import { WebauthnEnrollment } from './WebauthnEnrollment';
import { WebauthnVerification } from './WebauthnVerification';
import { AuthenticatorKey } from '../types';
/* eslint complexity:[0,8] */
export function getAuthenticator(remediation) {
    var _a, _b;
    const relatesTo = remediation.relatesTo;
    const value = (relatesTo === null || relatesTo === void 0 ? void 0 : relatesTo.value) || {};
    switch (value.key) {
        case AuthenticatorKey.OKTA_PASSWORD:
            return new OktaPassword(value);
        case AuthenticatorKey.SECURITY_QUESTION:
            if ((_a = value.contextualData) === null || _a === void 0 ? void 0 : _a.enrolledQuestion) {
                return new SecurityQuestionVerification(value);
            }
            else {
                return new SecurityQuestionEnrollment(value);
            }
        case AuthenticatorKey.OKTA_VERIFY:
            return new OktaVerifyTotp(value);
        case AuthenticatorKey.WEBAUTHN:
            if ((_b = value.contextualData) === null || _b === void 0 ? void 0 : _b.challengeData) {
                return new WebauthnVerification(value);
            }
            else {
                return new WebauthnEnrollment(value);
            }
        default:
            return new VerificationCodeAuthenticator(value);
    }
}

import { Authenticator } from './Authenticator';
// general authenticator to handle "verificationCode" input
// it can be used for "email", "phone", "google authenticator"
// a new authenticator class should be created if special cases need to be handled
export class VerificationCodeAuthenticator extends Authenticator {
    canVerify(values) {
        return !!(values.verificationCode || values.otp);
    }
    mapCredentials(values) {
        return { passcode: values.verificationCode || values.otp };
    }
    getInputs(idxRemediationValue) {
        var _a;
        return Object.assign(Object.assign({}, (_a = idxRemediationValue.form) === null || _a === void 0 ? void 0 : _a.value[0]), { name: 'verificationCode', type: 'string', required: idxRemediationValue.required });
    }
}
